
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { required } from "@/helpers/rules";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import store from "@/store";
import { BillingAddress } from "@/models/entities/billing-address.interface";
import { paymentsService } from "@/services/api/payments.service";
import { countryService } from "@/services/api/country.service";
import OverlayModule from "@/store/modules/overlay.module";
import { Country } from "@/models/entities/country.interface";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { subscriptionPlansService } from "@/services/api/subscription-plan.service";
import { userService } from "@/services/api/user.service";
import { UpgradePayment } from "@/models/entities/upgrade-payment";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);

@Component
export default class BillingAddressPage extends Vue {
  @Prop({ required: false, default: null, type: String })
  private previousPage: string;

  @Prop({ required: false, default: null, type: Number })
  private roleIdOld: number;

  @Prop({ required: false, default: null, type: Boolean })
  private month: boolean;

  @Ref("countryAutocomplete")
  private countryAutocomplete: HTMLElement;

  private required = required;
  private isFormValid = false;
  private countries: Country[] = [
    {
      countryID: 0,
      name: "",
      code2: "",
    },
  ];

  private billingAddress: BillingAddress = {
    companyName: "",
    vatID: "",
    sdiCode: "",
    companyAddress: "",
    city: "",
    postalCode: "",
    province: "",
    countryID: 0,
    countryDescr: "",
  };

  private promotionPlanOld: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    monthlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
    yearPrice: "",
  };

  private promotionPlanNew: PromotionPlans = {
    offertID: 0,
    nameOffert: "",
    descrOffert: "",
    price: "",
    monthlyBeneficiaries: "",
    totalSubjects: "",
    firstSupport: false,
    secondSupport: false,
    freeTrial: "",
    expiration: "",
    roleId: 0,
    priceMonthID: "",
    priceYearID: "",
    yearPrice: "",
  };

  private async created() {
    //  this.userData = (await paymentsService.retriveCustomer()).data;
    this.countries = (await countryService.GetAllCountry()).data;
    this.billingAddress = (await userService.GetBillingAddressByID()).data;

    overlayModule.showOverlay();
    this.promotionPlanOld = (
      await subscriptionPlansService.GetById(authModule.profileRoleID)
    ).data;
    overlayModule.showOverlay();
    this.promotionPlanNew = (
      await subscriptionPlansService.GetById(this.roleIdOld)
    ).data;
  }

  private async saveBillingAddress() {
    overlayModule.showOverlay();
    await userService.UpdateBillingAddress(this.billingAddress);
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "home",
      },
    });
  }

  private async goToPayment() {
    overlayModule.showOverlay();
    await userService.UpdateBillingAddress(this.billingAddress);
    var url = "";
    let payment: UpgradePayment = {
      durationMonth: this.month ? 1 : 12,
      priceId: this.month
        ? this.promotionPlanNew.priceMonthID
        : this.promotionPlanNew.priceYearID,
    };
    url = (await paymentsService.CreateSession(payment)).data;
    window.open(url, "_self");
    this.previousPage = "";
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "home",
      },
    });
  }

  private back() {
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "confirmplan",
      },
    });
  }

  private backToPayments() {
    this.$router.push({
      name: "account",
      params: {
        context: "payments",
        payments: "home",
      },
    });
  }

  private onCountryClick(): void {
    this.$vuetify.goTo(document.body.scrollHeight, {
      container: ".content-col",
    });
  }
}
